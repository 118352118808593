import React from 'react';
import './minter.css';
import Witch from 'D:/NFT_development/front_end/navigation-bar/src/witch.gif'
import Wizardao from 'D:/NFT_development/front_end/navigation-bar/src/wizardaogif.gif'
import relics from 'D:/NFT_development/front_end/navigation-bar/src/cursedcauldron.gif'
import charmcrystal from 'D:/NFT_development/front_end/navigation-bar/src/CharmCrystalRelic.gif'
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintOneNFT,
  mintThreeNFT,
  mintPixaNFT,
  mintOneRelic,
  mintMultipleDAO,
  mintNFTDAO,
  checkWitchesMinted,
  checkRelicsMinted,
  checkRelicsMintStatus,
  ethapprove,
  ethdecline,
  pixaapprove,
  pixadecline,
  stakePixa,
  unstakePixa,
  checkcurrentburnrate,
  checkcurrentrewardrate,
  executeBurn,
  executeReward,
  calculateCurrentRewards,
  checkSpecificRewards,
  checkcurrentpixavoteaddress,
  checkcurrentpixavotereason,
  checkcurrentpixavoteamount,
  checkcurrentpixavotestate,
  checkcurrentethvotestate,
  checkcurrentethvotereason,
  checkcurrentethvoteamount,
  checkcurrentethvoteaddress,
  checkcurrentpixatabs,
  checkcurrentpixavote,
  initiateBurn,
  initiateRewards,
  checktimetorewards,
  checktimetoburn,
  finalizeEth,
  finalizePixa,
  createEthVote,
  createPixaVote,
  claimNFTRewards,
  checkWizards
} from "D:/NFT_development/front_end/navigation-bar/src/pages/utils/interact.js";


const WitchMinted = 10;

const Home = () => {

	
	
	  //State variables
  let [name, setName] = useState("");
  const [wizstatus, setWizStatus] = useState("");
  const [WitchMinted, setWitchMintNumber] = useState("");
  const [RelicsMinted, setRelicMintNumber] = useState("");
  const [relicMintStatus, setRelicMintStatus] = useState("");
  const [specificrewardcontract, setCheckSpecificRewardsContract] = useState("")
  const [specificrewardtokenid, setCheckSpecificRewardtknID] = useState("")
  const [specificrewardsavailable, setCheckSpecificRewards] = useState("");
  const [stakeLevel, setStakeLevel] = useState("");
  const [totalrewardsavailable, setchecktimetorewards] = useState("");
  const [rewardsaddress, setRewardsCheckAddress] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [burnrate, setBurnRate] = useState("");
  const [rewardrate, setRewardRate] = useState(""); 
  const [ethtransfer, setethtransfer] = useState("");
  const [pixatransfer, setpixatransfer] = useState("");
  const [currentburnrate, setcurrentburnrate] = useState("");
  const [currentrewardrate, setcurrentrewardrate] = useState("");
  const [timetorewards, settimetorewards] = useState("");
  const [timetoburn, settimetoburn] = useState("");


  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    const { currentwitchesminted } = await checkWitchesMinted(); 
    setWitchMintNumber(currentwitchesminted)
    const { currentrelicsminted } = await checkRelicsMinted(); 
    setRelicMintNumber(currentrelicsminted)
    const { currentrelicsmintstatus } = await checkRelicsMintStatus(); 
    setRelicMintStatus(currentrelicsmintstatus)
    setBurnRate(10);
    setRewardRate(10);
    addWalletListener(); 
  }, []);



  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }


  const onMintOnePressed = async () => { //TODO: implement
      const { status } = await mintOneNFT();
    setStatus(status);  
  };

  const onMintThreePressed = async () => { //TODO: implement
      const { status } = await mintThreeNFT();
    setStatus(status);  
  };

  const onMintPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress);
    setStatus(status);  
  };

  const onMintOneRelicPressed = async () => { //TODO: implement
      const { status } = await mintOneRelic();
    setStatus(status);  
  };

  const onMintOneDaoPressed = async () => { //TODO: implement
      const { status } = await mintNFTDAO();
    setStatus(status);  
  };

  const onMintAllDaoPressed = async () => { //TODO: implement
      const { status } = await mintMultipleDAO();
    setStatus(status);  
  };

    const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkWizards(name);
    setWizStatus(wizstatus);  
  };





return (


<div class="wrapper">



  <div class="title">


<text id="textt">This site is the mobile minter. For full web3 functionality over the Pixa ecosystem (ie. rewards claims, PixaWitch upgrading) please visit the app on your desktop.</text>
<br></br>
<br></br>
	  <text id= "textt" > Read the terms and conditions you agree to by using this application on the desktop app link.</text>
    <br></br>
    <br></br>
	  


      <form id="webbutton" action="https://app.pixanft.com">
        <input id="webbutton" type="submit" value="Desktop App" />
      </form> 
	  <br></br>


	<text> <button id="connectwallet" onClick={connectWalletPressed}>
    {walletAddress.length > 0 ? (
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span id = "buttonsize"> Connect Wallet</span>
        )}
    </button>
	</text>	


<br></br>

	  



<br></br>

	    

 

	  </div>

  
 
	  


  <div class="onetext">

	<h2> PixaWitches</h2>
  <h2>  <img src={Witch} alt="Image" height={300} width={245} border="5px solid black" style={{alignItems: 'Center'}} /> </h2>	
	 
     	  <text></text>
	  	  <text> Minted:</text>
	<text>
		 {WitchMinted} / 2000</text>

	<text> <button id="mintButton2" onClick={onMintOnePressed}>
    1 NFT for 0.03 ETH
    </button></text>
	<text> <button id="mintButton2" onClick={onMintThreePressed}>
    3 NFTs for 0.06 ETH
    </button> </text>
	<text> <button id="mintButton2" onClick={onMintPixaPressed} >
    1 NFT for 1500 $PIXA
    </button>  </text>
	  </div>  


 




	  <div class="twotext">

      	<h2> Relics of the PixaRealm</h2>

        	<h2> <img src={charmcrystal} alt="Image" height={250} width={250} border="5px solid #000" style={{alignItems: 'Left'}} /> </h2>
       	  <text></text>
          
	  	  <text>Minted: {RelicsMinted}</text>

              
	<text> <button id="mintButton2" onClick={onMintOneRelicPressed}>
    {relicMintStatus}
    </button></text>
          	<text> </text>
             	<text> </text>
               	<text> Next mint: "The Charm Crystal" - March 26th at 4pm EST</text>
            	<text> </text>           
	<text> </text>
	  </div>  






	  <div class="threetext">

      	<h2> WizarDAO Token</h2>
	  
	<h2><img src={Wizardao} alt="Image" height={250} width={250} border="5px solid #000" style={{alignItems: 'Left'}} /></h2>
	  
	   


	  	  <text></text>
<text></text>
 	  <text></text>
      	  <text></text>
	<text> <button id="mintButton2" onClick={onMintOneDaoPressed}>
    Claim Token
    </button></text>
	<text> <button id="mintButton2" onClick={onMintAllDaoPressed}>
    Claim All Tokens
    </button>
    
    
    
    </text>

<text> <h5> PixaWizard TokenID: </h5></text>


	<text> 	        <input id="entry1"
          type="number"
          placeholder="TokenID #"
          onChange={(event) => setName(event.target.value)}
        /> </text>



	<text> <button id="mintButton2" onClick={onCheckPressed}>
    Check Claim
    </button></text>
	<text> <h5> Status: {wizstatus} </h5></text>


	  </div>  














</div>

);
};

export default Home;
