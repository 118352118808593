import React from 'react';
import './wizardao.css';
import Witch from 'D:/NFT_development/front_end/navigation-bar/src/witch.gif'
import Wizardao from 'D:/NFT_development/front_end/navigation-bar/src/wizardaogif.gif'
import relics from 'D:/NFT_development/front_end/navigation-bar/src/cursedcauldron.gif'
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintOneNFT,
  mintThreeNFT,
  mintPixaNFT,
  mintOneRelic,
  mintMultipleDAO,
  mintNFTDAO,
  checkWitchesMinted,
  checkRelicsMinted,
  checkRelicsMintStatus,
  ethapprove,
  ethdecline,
  pixaapprove,
  pixadecline,
  stakePixa,
  unstakePixa,
  checkcurrentburnrate,
  checkcurrentrewardrate,
  executeBurn,
  executeReward,
  calculateCurrentRewards,
  checkSpecificRewards,
  checkcurrentpixavoteaddress,
  checkcurrentpixavotereason,
  checkcurrentpixavoteamount,
  checkcurrentpixavotestate,
  checkcurrentethvotestate,
  checkcurrentethvotereason,
  checkcurrentethvoteamount,
  checkcurrentethvoteaddress,
  checkcurrentpixatabs,
  checkcurrentpixavote,
  initiateBurn,
  initiateRewards,
  checktimetorewards,
  checktimetoburn,
  finalizeEth,
  finalizePixa,
  createEthVote,
  createPixaVote,
  claimNFTRewards,
  checkWizards
} from "D:/NFT_development/front_end/navigation-bar/src/pages/utils/interact.js";


const WitchMinted = 10;

const WizarDAO = () => {

	  //State variables
  let [name, setName] = useState("");
  const [wizstatus, setWizStatus] = useState("");
  const [daotime2rewards, setTimeToRewardsDAO] = useState("");
  const [daotimeburn, setTimeToBurnDAO] = useState("");
  const [WitchMinted, setWitchMintNumber] = useState("");
  const [RelicsMinted, setRelicMintNumber] = useState("");
  const [relicMintStatus, setRelicMintStatus] = useState("");
	const [PRR, setPastRewardRate] = useState("");
	const [PBR, setPastBurnRate] = useState("");
  const [specificrewardcontract, setCheckSpecificRewardsContract] = useState("")
  const [specificrewardtokenid, setCheckSpecificRewardtknID] = useState("")
  const [specificrewardsavailable, setCheckSpecificRewards] = useState("");
  const [stakeLevel, setStakeLevel] = useState("");
  const [totalrewardsavailable, setchecktimetorewards] = useState("");
  const [rewardsaddress, setRewardsCheckAddress] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [RewardsRateUpdate, setRewardsRateUpdate] = useState("");
  const [BurnRateUpdate, setBurnRateUpdate] = useState("");
  const [burnrate, setBurnRate] = useState("");
  const [rewardrate, setRewardRate] = useState(""); 
  const [ethtransfer, setethtransfer] = useState("");
  const [pixatransfer, setpixatransfer] = useState("");
  const [currentburnrate, setcurrentburnrate] = useState("");
  const [currentrewardrate, setcurrentrewardrate] = useState("");
  const [timetorewards, settimetorewards] = useState("");
  const [timetoburn, settimetoburn] = useState("");


  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    const { currentwitchesminted } = await checkWitchesMinted(); 
    setWitchMintNumber(currentwitchesminted)
    const { currentrelicsminted } = await checkRelicsMinted(); 
    setRelicMintNumber(currentrelicsminted)
    const { currentrelicsmintstatus } = await checkRelicsMintStatus(); 
    setRelicMintStatus(currentrelicsmintstatus)
	const { ti2rew } = await checktimetorewards();
	setTimeToRewardsDAO(ti2rew);
	const { timetoburn } = await checktimetoburn();
	setTimeToBurnDAO(timetoburn);
	const { amounttoburn } = await checkcurrentburnrate();
	setPastBurnRate(amounttoburn);
	const { amounttoreward } = await checkcurrentrewardrate();
	setPastRewardRate(amounttoreward);

    setBurnRate(10);
    setRewardRate(10);
    addWalletListener(); 
  }, []);



  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }


  const onMintOnePressed = async () => { //TODO: implement
      const { status } = await mintOneNFT();
    setStatus(status);  
  };

  const onMintThreePressed = async () => { //TODO: implement
      const { status } = await mintThreeNFT();
    setStatus(status);  
  };

  const onMintPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress);
    setStatus(status);  
  };

  const onMintOneRelicPressed = async () => { //TODO: implement
      const { status } = await mintOneRelic();
    setStatus(status);  
  };

  const onMintOneDaoPressed = async () => { //TODO: implement
      const { status } = await mintNFTDAO();
    setStatus(status);  
  };

  const onMintAllDaoPressed = async () => { //TODO: implement
      const { status } = await mintMultipleDAO();
    setStatus(status);  
  };

    const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkWizards(name);
    setWizStatus(wizstatus);  
  };

    const onExecuteRewardsPressed = async () => { //TODO: implement
    executeReward(RewardsRateUpdate);
  };

    const onExecuteBurnPressed = async () => { //TODO: implement
      executeBurn(BurnRateUpdate);
  };


return (
<div class="wrapperw">
	<div class="titlew">
	  
	  <h1> WizarDAO Interface </h1>

	  <text > Read the terms and conditions you agree to by using this application.</text>
	  
	  </div>
  <div class="onetitlew">
	<h2> Snapshot Voting:</h2>
	  

	  </div>  


 	<div class="titleconnectwallet"> 
	<text> <button id="connectwallet" onClick={connectWalletPressed} >
     {walletAddress.length > 0 ? (
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
    </button>
	</text>	

 	</div>

  <div class="onetextw">
    <text> Vote on Pixa community decisions through our wizardao.eth snapshot portal</text>


<text>     <form id="webbutton2" action="https://snapshot.org/#/wizardao.eth">
        <input id="webbutton" type="submit" value="Vote at snapshot.org"  />
      </form> </text>

	  </div>

  



	  <div class="twotitleimagedao">
	<img src={Wizardao} alt="Image" height={250} width={250} border="5px solid black" style={{alignItems: 'Center'}} />
	  
	  </div>  


	  <div class="twotitlew">
	<h2> Rewards System Allocation:</h2>
	  
	  </div>  

	  <div class="twotextw">


        <text> Current rate: {PRR} / week</text>
	<text>
		
        <input id="entry"
          type="number"
          placeholder="#"
		  onChange={(event) => setRewardsRateUpdate(+event.target.value+ 10)}
        />
		

	<button id="mintButton2" onClick={onExecuteRewardsPressed}>
    Execute
    </button> </text>
		<text> 	<button id="mintButton2" onClick={initiateRewards}>
    Initiate Rewards
    </button> <h6> Next rewards transfer can be initiated after: {daotime2rewards} </h6></text>

	  </div>  





 		<div class="twodescriptiontitlew">
	<h3> Description:</h3>
	  
 </div>  
	<div class="twodescriptionw">



		  
	  	  <text></text>
	<text> Increase/decrease the weekly rewards sent to the rewards contract. Enter a number between -10 to 10, which will increase or decrease the allocation per WizarDAO NFT you have.  </text>
<text> Press "Initiate Rewards" to send the weekly allocation to the rewards contract, and get a bonus 1000 $PIXA.</text>

	  </div>  






	  <div class="threetitlew">
	<h2> $PIXA Burn Rate:</h2>
	  
	  </div>  

	  <div class="threetextw">

		
		<text> Current rate: {PBR} / week</text>
	<text>
        <input id="entry"
          type="number"
          placeholder="#"
		  onChange={(event) => setBurnRateUpdate(+event.target.value + 10)}
        />
		

	<button id="mintButton2" onClick={onExecuteBurnPressed}>
    Execute
    </button></text>
		<text> 	<button id="mintButton2" onClick={initiateBurn}>
    Initiate Burn
    </button> <h6> Next burn can be initiated after: {daotimeburn} </h6></text>

	  </div>  





 		<div class="threedescriptiontitlew">
	<h3> Description:</h3>
	  
 </div>  
	<div class="threedescriptionw">



		  
	  	  <text></text>
	<text> Increase/decrease the weekly $PIXA burn. Enter a number between -10 to 10, which will increase or decrease the burn rate per WizarDAO NFT you have. </text>
			<text> Press "Initiate Burn" to burn the weekly $PIXA, and get a bonus 1000 $PIXA. </text>

	  </div>  







	  
	
</div>
);
};

export default WizarDAO;
