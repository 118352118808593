import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/index';
import Minter from './pages/minter';
import Rewards from './pages/rewards';
import WizarDAO from './pages/Wizardao';
import MyWallet from './pages/MyWallet';

function App() {
return (
	<Router>
	<Navbar />
	<Switch>t 
		<Route path='/' exact component={Home} />
		<Route path='/rewards' component={Rewards} />
		<Route path='/minter' component={Minter} />
		<Route path='/Wizardao' component={WizarDAO} />
		<Route path='/MyWallet' component={MyWallet} />
	</Switch>
	</Router>
);
}

export default App;

