import React from 'react';
import {
Nav,
NavLink,
Bars,
NavMenu,
NavBtn,
NavBtnLink,
} from './NavbarElements';
import logo from 'D:/NFT_development/front_end/navigation-bar/src/components/Navbar/logo.png'

const Navbar = () => {
return (
	<>
	<Nav>
		<Bars />
		<NavLink to='/' activeStyle>
		<img src={logo} alt="Image" height={50} width={95} style={{alignItems: 'Left'}} />
	    </NavLink>

	</Nav>
	</>
);
};

export default Navbar;
