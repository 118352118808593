import React from 'react';
import './minter.css';
import Witch from 'D:/NFT_development/front_end/navigation-bar/src/witch.gif'
import Wizardao from 'D:/NFT_development/front_end/navigation-bar/src/wizardaogif.gif'
import relics from 'D:/NFT_development/front_end/navigation-bar/src/cursedcauldron.gif'
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintOneNFT,
  mintThreeNFT,
  mintPixaNFT,
  mintOneRelic,
  mintMultipleDAO,
  mintNFTDAO,
  checkWitchesMinted,
  checkRelicsMinted,
  checkRelicsMintStatus,
  ethapprove,
  ethdecline,
  pixaapprove,
  pixadecline,
  stakePixa,
  unstakePixa,
  checkcurrentburnrate,
  checkcurrentrewardrate,
  executeBurn,
  executeReward,
  calculateCurrentRewards,
  checkSpecificRewards,
  checkcurrentpixavoteaddress,
  checkcurrentpixavotereason,
  checkcurrentpixavoteamount,
  checkcurrentpixavotestate,
  checkcurrentethvotestate,
  checkcurrentethvotereason,
  checkcurrentethvoteamount,
  checkcurrentethvoteaddress,
  checkcurrentpixatabs,
  checkcurrentpixavote,
  initiateBurn,
  initiateRewards,
  checktimetorewards,
  checktimetoburn,
  finalizeEth,
  finalizePixa,
  createEthVote,
  createPixaVote,
  claimNFTRewards,
  checkWizards
} from "D:/NFT_development/front_end/navigation-bar/src/pages/utils/interact.js";


const WitchMinted = 10;

const Minter = () => {

	  //State variables
  let [name, setName] = useState("");
  const [wizstatus, setWizStatus] = useState("");
  const [WitchMinted, setWitchMintNumber] = useState("");
  const [RelicsMinted, setRelicMintNumber] = useState("");
  const [relicMintStatus, setRelicMintStatus] = useState("");
  const [specificrewardcontract, setCheckSpecificRewardsContract] = useState("")
  const [specificrewardtokenid, setCheckSpecificRewardtknID] = useState("")
  const [specificrewardsavailable, setCheckSpecificRewards] = useState("");
  const [stakeLevel, setStakeLevel] = useState("");
  const [totalrewardsavailable, setchecktimetorewards] = useState("");
  const [rewardsaddress, setRewardsCheckAddress] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [burnrate, setBurnRate] = useState("");
  const [rewardrate, setRewardRate] = useState(""); 
  const [ethtransfer, setethtransfer] = useState("");
  const [pixatransfer, setpixatransfer] = useState("");
  const [currentburnrate, setcurrentburnrate] = useState("");
  const [currentrewardrate, setcurrentrewardrate] = useState("");
  const [timetorewards, settimetorewards] = useState("");
  const [timetoburn, settimetoburn] = useState("");


  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    const { currentwitchesminted } = await checkWitchesMinted(); 
    setWitchMintNumber(currentwitchesminted)
    const { currentrelicsminted } = await checkRelicsMinted(); 
    setRelicMintNumber(currentrelicsminted)
    const { currentrelicsmintstatus } = await checkRelicsMintStatus(); 
    setRelicMintStatus(currentrelicsmintstatus)
    setBurnRate(10);
    setRewardRate(10);
    addWalletListener(); 
  }, []);



  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }


  const onMintOnePressed = async () => { //TODO: implement
      const { status } = await mintOneNFT();
    setStatus(status);  
  };

  const onMintThreePressed = async () => { //TODO: implement
      const { status } = await mintThreeNFT();
    setStatus(status);  
  };

  const onMintPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress);
    setStatus(status);  
  };

  const onMintOneRelicPressed = async () => { //TODO: implement
      const { status } = await mintOneRelic();
    setStatus(status);  
  };

  const onMintOneDaoPressed = async () => { //TODO: implement
      const { status } = await mintNFTDAO();
    setStatus(status);  
  };

  const onMintAllDaoPressed = async () => { //TODO: implement
      const { status } = await mintMultipleDAO();
    setStatus(status);  
  };

    const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkWizards(name);
    setWizStatus(wizstatus);  
  };


return (

<div class="wrapper">
  <div class="title">
	  
	  <h1> NFT Minter </h1>

	  <text > Read the terms and conditions you agree to by using this application.</text>
	  
	  </div>
  <div class="onetitle">
	<h2> PixaWitches</h2>
	  
	  </div>  


 	<div class="title2"> 
 
 	</div>

  <div class="onetext">
	<img src={Witch} alt="Image" height={300} width={245} border="5px solid black" style={{alignItems: 'Left'}} />
	  
	  </div>   


  <div class="onetext">
     	  <text></text>
	  	  <text> Minted:</text>
	<text>
		 {WitchMinted} / 2000</text>

	<text> <button id="mintButton2" onClick={onMintOnePressed}>
    1 NFT for 0.03 ETH
    </button></text>
	<text> <button id="mintButton2" onClick={onMintThreePressed}>
    3 NFTs for 0.06 ETH
    </button> </text>
	<text> <button id="mintButton2" onClick={onMintPixaPressed} >
    1 NFT for 1500 $PIXA
    </button>  </text>
	  </div>  

	   	<div class="titleconnectwallet"> 
	<text> <button id="connectwallet" onClick={connectWalletPressed}>
    {walletAddress.length > 0 ? (
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
    </button>
	</text>	

 	</div>

 


		<div class="onedescriptiontitle">
	<h3> Description:</h3>
	  
 </div>  
	<div class="onedescription">
	  	<h4> </h4>
	  <text width={20}> The PixaWitches are 2,000 autogenerated NFTs that occupy the PixaRealm. They are a "Faction" collection, and are the second "Faction" created by Pixa. You can upgrade their "Rank" trait by spending $PIXA!  </text>
	  </div>  



	  <div class="twotitle">
	<h2> Relics of the PixaRealmmmmmm</h2>
	  
	  </div>  


	  <div class="twoimage">
	<img src={relics} alt="Image" height={250} width={250} border="5px solid #000" style={{alignItems: 'Left'}} />
	  
	  </div>  


	  <div class="twotext">
       	  <text></text>
          
	  	  <text>Minted: {RelicsMinted}</text>

              
	<text> <button id="mintButton2" onClick={onMintOneRelicPressed}>
    {relicMintStatus}
    </button></text>
          	<text> </text>
             	<text> </text>
               	<text> Next mint: TBA</text>
            	<text> </text>           
	<text> </text>
	  </div>  




 		<div class="twodescriptiontitle">
	<h3> Description:</h3>
	  
 </div>  
	<div class="twodescription">
	  	<h3> </h3>
	  <text width={20}> The Relics of the PixaRealm are free mints that are made available periodically for a limited time! This collection will be limited to 100 types of Relics. Join our <a href="https://discord.gg/pixa" rel="noreferrer">
    discord
  </a> so you don't miss a mint! </text>
	  </div>  


	  <div class="threetitle">
	<h2> WizarDAO Token</h2>
	  
	  </div>  
	  <div class="threetext">
	<img src={Wizardao} alt="Image" height={250} width={250} border="5px solid #000" style={{alignItems: 'Left'}} />
	  
	  </div>  

	  <div class="threetext">
	  	  <text></text>
<text></text>
 	  <text></text>
      	  <text></text>
	<text> <button id="mintButton2" onClick={onMintOneDaoPressed}>
    Claim Token
    </button></text>
	<text> <button id="mintButton2" onClick={onMintAllDaoPressed}>
    Claim All Tokens
    </button>
    
    
    
    </text>

<text> <h5> PixaWizard TokenID: </h5></text>


	<text> 	        <input id="entry1"
          type="number"
          placeholder="TokenID #"
          onChange={(event) => setName(event.target.value)}
        /> </text>



	<text> <button id="mintButton2" onClick={onCheckPressed}>
    Check Claim
    </button></text>
	<text> <h5> Status: {wizstatus} </h5></text>


	  </div>  


 		<div class="threedescriptiontitle">
	<h3> Description:</h3>
	  
 </div>  

	<div class="threedescription">
	  	<h3> </h3>
	  <text width={20}> The WizarDAO NFT is the governance token of the Pixa NFT ecosystem. The WizarDAO controls the tokenomics of our utility token, $PIXA. WizarDAO NFTs can be claimed by PixaWizard owners, check if your wizard has a claim available using the tool on the left. </text>
	  </div>  





	  <div class="fourtitle">
	<h2> </h2>
	  
	  </div>  

	  
	
</div>

);
};

export default Minter;
