require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 

const contractABI = require('../contract-abi.json')
const contractAddress = "0xbD16C356e4dea7b0bB821b853F684ddEB8Dd1620";

const rewardscontractABI = require('../contract-abi-rewards.json')
const rewardscontractAddress = "0x71d9D0Aa37081958E8938bEd37c0954ef0b0c61E";

const contractABIpixa = require('../contract-abi-pixa.json')
const contractAddresspixa = "0xeaf211cD484118a23AD71C3F9073189C43d1311c";


// import abis to call from other contracts. Need this for 
const wizardcontractABI = require('../contract-abi-pixawizards.json')
const wizardcontractAddress = "0xc6b0B290176AaaB958441Dcb0c64ad057cBc39a0";
const wyverncontractABI = require('../contract-abi-pixawyverns.json')
const wyverncontractAddress = "0xb144ec7d231ddde69d8790e5a868a64572a0845b";
const pixalecontractABI = require('../contract-abi-pixale.json')
const pixalecontractAddress = "0x6B6B77c3ac72C76Fb7F57444782869dE427A51b5";
const relicscontractABI = require('../contract-abi-relics.json')
const relicscontractAddress = "0x0f1255d9e644195cc6a040adfe7efe2fa7d64fce";
const wizardaocontractABI = require('../contract-abi-wizardao.json')
const wizardaocontractAddress = "0xbd16c356e4dea7b0bb821b853f684ddeb8dd1620";
const witchescontractABI = require('../contract-abi-witches.json')
const witchescontractAddress = "0xD8ab2B8b8A6036DcB91D44cbDf6e9a42a199EBE2";



export const connectWallet = async () => {
      const chainID = await window.ethereum.request({
          method: "eth_chainId",
         
        });  

    if (window.ethereum && chainID == 1) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        
        return obj;

      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

export const getCurrentWalletConnected = async () => {



  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Press mint button",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

//export const checkWizards = async(name) => {
//set up your Ethereum transaction
//  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
//  let wazaaa = await window.contract.methods.wizlist.call(2,function(err, res){
//  }).encodeABI();
  
//make call to NFT smart contract 

//  return {status: wazaaa}
//}



export const executeburnrate = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.wizlist(name).call();
  
  if (wazaaa == true) {
    var result = "Claimed";
  } 
  else {
    var result = "Unclaimed";
  }
//make call to NFT smart contract 

  return {wizstatus: result}
}


export const executerewardrate = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.wizlist(name).call();
  
  if (wazaaa == true) {
    var result = "Claimed";
  } 
  else {
    var result = "Unclaimed";
  }
//make call to NFT smart contract 

  return {wizstatus: result}
}



export const checktimetorewards = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var unix_timestamp = await window.contract.methods.newRewardsTimeFrame().call();
  
//make call to NFT smart contract 
  var date = new Date(unix_timestamp * 1000);
// Hours part from the timestamp
  var hours = date.getHours();
// Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
// Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

// Will display time in 10:30:23 format
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

  return {ti2rew: date.toLocaleDateString()}
}




export const checktimetoburn = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var unix_timestamp = await window.contract.methods.newTimeFrame().call();
  
//make call to NFT smart contract 
  var date = new Date(unix_timestamp * 1000);
// Hours part from the timestamp
  var hours = date.getHours();
// Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
// Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

// Will display time in 10:30:23 format
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

  return {timetoburn: date.toLocaleDateString()}
}




export const checkcurrentburnrate = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.burnAmount().call();
  
//make call to NFT smart contract 

  return {amounttoburn: result}
}


export const checkcurrentrewardrate = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.rewardAmount().call();
  
//make call to NFT smart contract 

  return {amounttoreward: result}
}




export const checkcurrentpixavotereason = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixareason().call();
  
//make call to NFT smart contract 

  return {currentpixavotereason: result}
}

export const checkcurrentpixavoteamount = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixaamount().call();
  
//make call to NFT smart contract 

  return {currentpixavoteamount: result}
}

export const checkcurrentpixavoteaddress= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixarecipient().call();
  
//make call to NFT smart contract 

  return {currentpixavoteaddress: result}
}

export const checkcurrentpixavotestate= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixavotingopen().call();
  
//make call to NFT smart contract 

  return {currentpixavoteaddress: result}
}

export const checkcurrentpixavote= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixavotecount().call();
  
//make call to NFT smart contract 

  return {currentpixavote: result}
}


export const checkcurrentpixatabs= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixavoteamount().call();
  var finalresult = +result - 10000;
//make call to NFT smart contract 

  return {currentpixavotetabs: finalresult}
}




export const checkcurrentethvotereason = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.ethreason().call();
  
//make call to NFT smart contract 

  return {currentethvotereason: result}
}

export const checkcurrentethvoteamount = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.ethamount().call();
  
//make call to NFT smart contract 

  return {currentethvoteamount: result}
}

export const checkcurrentethvoteaddress= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixarecipient().call();
  
//make call to NFT smart contract 

  return {currentethvoteaddress: result}
}

export const checkcurrentethvotestate= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.ethvotingopen().call();
  
//make call to NFT smart contract 

  return {currentethvoteaddress: result}
}



export const createEthVote = async (recipient, amount, reason) => {

  try {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          value: parseInt(web3.utils.toWei("0.1","ether")).toString(16),
          'data': window.contract.methods.proposeTransferEth(recipient, amount, reason).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

  } catch {}

};


export const createPixaVote = async (recipient, amount, reason) => {

  try {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          value: parseInt(web3.utils.toWei("0.1","ether")).toString(16),
          'data': window.contract.methods.proposeTransferPixa(recipient, amount, reason).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

  } catch{}
};




export const pixadecline = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.votingTransferPixa(true).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};



export const pixaapprove = async () => {

  if (checkcurrentpixavotestate()) {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.votingTransferPixa(false).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }
  }
 else {}

};





export const ethapprove = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.votingTransferEth(true).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};



export const ethdecline = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.votingTransferEth(false).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};


export const executeBurn = async (number) => {

  if (number >= 0 && number <21) {

    if (number == 0) {
      number = 1;
    }
    

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.burnVoting(number).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

  }
  else {
  }

};


export const executeReward = async (number) => {

  if (number >= 0 && number <21) {

    if (number == 0) {
      number = 1;
    }
    

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.rewardsVoting(number).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

  }
  else {
  }

};





export const initiateBurn = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.weeklyBurn().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};




export const initiateRewards = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.weeklyRewards().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};


export const finalizeEth = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.initiateTransferEth().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};



export const finalizePixa = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.initiateTransferPixa().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};



export const claimNFTRewards = async () => {

    window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
    const transactionParameters = {
          to: rewardscontractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.claimRewards().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }


};



export const stakePixa = async (stakeLevel) => {

    window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
    const transactionParameters = {
          to: rewardscontractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.stakePixa(stakeLevel).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }


};




export const unstakePixa = async (stakeLevel) => {

    window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
    const transactionParameters = {
          to: rewardscontractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.unstakePixa().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }


};




export const calculateCurrentRewards= async(addressowner) => {
//var addressowner = rewardsaddress; //"0x7cA6B4645B71f874A35Bec18F0e997E51D8d815C"

//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
  window.contract2 = await new web3.eth.Contract(wizardcontractABI, wizardcontractAddress);
  window.contract3 = await new web3.eth.Contract(wyverncontractABI, wyverncontractAddress);
  window.contract4 = await new web3.eth.Contract(pixalecontractABI, pixalecontractAddress);
  window.contract5 = await new web3.eth.Contract(relicscontractABI, relicscontractAddress);
  window.contract6 = await new web3.eth.Contract(wizardaocontractABI, wizardaocontractAddress);
  window.contract7 = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);

  var result1 = await window.contract2.methods.balanceOf(addressowner).call();
  var result2 = await window.contract3.methods.balanceOf(addressowner).call();
  var result3 = await window.contract4.methods.balanceOf(addressowner).call();
  var result4 = await window.contract5.methods.balanceOf(addressowner).call();
  var result5 = await window.contract6.methods.balanceOf(addressowner).call();
  var result6 = await window.contract7.methods.balanceOf(addressowner).call();
  var timeremaining = await window.contract.methods.claimerTime(addressowner).call();
  var stakelevel = await window.contract.methods.currentStakingLevel(addressowner).call();
  var stkamt = await window.contract.methods.currentStaking(addressowner).call();

  var total = result1*100 + result2*70 + result3*35 + result4*5 + result5*100 + result6*50;
  var bvmult = 1;

  if (total >= 100) {
    bvmult = 2;
  }
  if (total >= 300) {
    bvmult = 3;
  }
  if (total > 1000) {
    bvmult = 4;
  }
  if (total > 3000) {
    bvmult = 5;
  }

  var finalTotal = (parseInt(stakelevel)+ 1)*bvmult*total;


  //make call to NFT smart contract 
  var date = new Date(timeremaining * 1000);
// Hours part from the timestamp
  var hours = date.getHours();
// Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
// Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

// Will display time in 10:30:23 format
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  return {totalrewardsavailable: finalTotal, timeleft: date.toLocaleDateString(), amountstaked:stkamt}
}











export const checkSpecificRewards= async(speccontract, tknid) => {
//var addressowner = rewardsaddress; //"0x7cA6B4645B71f874A35Bec18F0e997E51D8d815C"

//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);

  if (speccontract == "select") {
    amount = " Select Collection "
  } else {

  if (speccontract == "wizards") {
  var result1 = await window.contract.methods.NFTtime(0,tknid).call();
  var reward = 30
  }
  if (speccontract == "witches") {
  var result1 = await window.contract.methods.NFTtime(5,tknid).call();
  var reward = 15
  }
  if (speccontract == "pixale") {
  var result1 = await window.contract.methods.NFTtime(3,tknid).call();
  var reward = 10
  }
  if (speccontract == "wyverns") {
  var result1 = await window.contract.methods.NFTtime(1,tknid).call();
  var reward = 20
  }
  if (speccontract == "relics") {
  var result1 = await window.contract.methods.NFTtime(4,tknid).call();
  var reward = 2
  }
  if (speccontract == "wizardao") {
  var result1 = await window.contract.methods.NFTtime(2,tknid).call();
  var reward = 15
  }
  var currentTime = 0.001*Date.now()
  var amount = 0

  if (parseInt(result1) > currentTime) {
    amount = amount;
  }
  if (parseInt(result1) < currentTime) {
    amount = reward + amount;
  }
  if (parseInt(result1) + 604800 < currentTime) {
    amount = reward + amount;
  }
  if (parseInt(result1) + 1209600 < currentTime) {
    amount = reward + amount;
  }
  if (parseInt(result1) + 1814400 < currentTime) {
    amount = reward + amount;
  }


  }
  

  return {specificrewardsavailable: amount}
}


export const mintNFT = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.claimWitch().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}


export const mintMultiple = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.claimWitchLoop().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }


}


//export const checkWizards = async(name) => {
//set up your Ethereum transaction
//  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
//  let wazaaa = await window.contract.methods.wizlist.call(2,function(err, res){
//  }).encodeABI();
  
//make call to NFT smart contract 

//  return {status: wazaaa}
//}

export const checkWizards = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(wizardaocontractABI, wizardaocontractAddress);
  var wazaaa = await window.contract.methods.wizlist(name).call();
  
  if (wazaaa == true) {
    var result = "Claimed";
  } 
  else {
    var result = "Unclaimed";
  }
//make call to NFT smart contract 

  return {wizstatus: result}
}


export const checkWitchesMinted = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
  var wazaaa = await window.contract.methods.totalSupply().call();

//make call to NFT smart contract 

  return {currentwitchesminted: wazaaa}
}



export const checkRelicsMinted = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(relicscontractABI, relicscontractAddress);
  var wazaaa = await window.contract.methods.totalSupply().call();

//make call to NFT smart contract 

  return {currentrelicsminted: wazaaa}
}


export const checkRelicsMintStatus = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(relicscontractABI, relicscontractAddress);
  var wazaaa = await window.contract.methods.saleStatus().call();

//make call to NFT smart contract 

  if (wazaaa == false) {
    var result = "Minting Closed";
  } 
  else {
    var result = "Minting LIVE";
  }


  return {currentrelicsmintstatus: result}
}


export const checkUsersNFTs = async(walletAddress) => {
//set up your Ethereum transaction
  window.contract1 = await new web3.eth.Contract(wizardcontractABI, wizardcontractAddress);
  window.contract2 = await new web3.eth.Contract(wyverncontractABI, wyverncontractAddress);
  window.contract3 = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
  window.contract4 = await new web3.eth.Contract(relicscontractABI, relicscontractAddress); 
  window.contract5 = await new web3.eth.Contract(pixalecontractABI, pixalecontractAddress);


  var wizardnum = await window.contract1.methods.balanceOf(walletAddress).call();


  var wizardarray = [];
   for (var j = 0; j < wizardnum; j++) {
      const wizardID = await window.contract1.methods.tokenOfOwnerByIndex(walletAddress,j).call();
      wizardarray = [...wizardarray, wizardID]
      
    };
  
  if (wizardnum == 0){
    wizardarray = [0,0]
  }

  var wyvernnum = await window.contract2.methods.balanceOf(walletAddress).call();


  var wyvernarray = [];
   for (var j = 0; j < wyvernnum; j++) {
      const wyvernID = await window.contract2.methods.tokenOfOwnerByIndex(walletAddress,j).call();
      wyvernarray = [...wyvernarray, wyvernID]
      
    };
  
  if (wyvernnum == 0){
    wyvernarray = [0,0]
  }





  var witchnum = await window.contract3.methods.balanceOf(walletAddress).call();


   var witcharray = [];
   for (var j = 0; j < witchnum; j++) {
      const witchID = await window.contract3.methods.tokenOfOwnerByIndex(walletAddress,j).call();
      witcharray = [...witcharray, witchID]

    }; 
  if (witchnum == 0){
    witcharray = [0,0]
  }




  var pixalenum = await window.contract5.methods.balanceOf(walletAddress).call();


   var pixalearray = [];
   for (var j = 0; j <pixalenum; j++) {
      const pixaleID = await window.contract5.methods.tokenOfOwnerByIndex(walletAddress,j).call();
      pixalearray = [...pixalearray, pixaleID]

    }; 
  if (pixalenum == 0){
    pixalearray = [0,0]
  }


    var relicnum = await window.contract4.methods.balanceOf(walletAddress).call();
   var relicarray = [];
   var relictypearray = [];
   for (var j = 0; j < relicnum; j++) {
      var relicID = await window.contract4.methods.tokenOfOwnerByIndex(walletAddress,j).call();
      var relicstype = relicID
      if (relicID < 37) {
      relicstype = 1
      }
      if (relicID > 36 &&  relicID < 92) {  
      relicstype = 2
      } 
      if (relicID > 91 &&  relicID < 295) {  
      relicstype = 3
      } 
      relicarray = [...relicarray, relicID]
      relictypearray = [...relictypearray, relicstype]
    }; 
//make call to NFT smart contract 
  if (relicnum == 0){
    relicarray = [0,0]
  }

  return {wizards: wizardarray, numwizards: wizardnum, witches:witcharray, numwitches:witchnum, relics:relicarray, numrelics: relicnum, relictype: relictypearray, wyverns: wyvernarray, numwyverns:wyvernnum,pixale: pixalearray, numpixale:pixalenum}
}


export const checkWitchURI = async(num) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
  var result = await window.contract.methods.tokenURI(num).call();

  var finalURI = result + ".gif"
    return {witchURI: finalURI}
}

export const checkRelicURI = async(num) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(relicscontractABI, relicscontractAddress);
  var result = await window.contract.methods.tokenURI(num).call();

  var finalURI = result + ".gif"
    return {relicURI: finalURI}
}

export const tokenURIfinder = async(name,walletAddress) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
  var result = await window.contract.methods.tokenURI(name).call();
  var thebalance = await window.contract.methods.balanceOf(walletAddress).call();
  var rank = await window.contract.methods.rankTracker(name).call();
//make call to NFT smart contract 

  if (rank == 0){
    var rankWord = "Standard";
  }
  if (rank == 1){
    var rankWord = "Bronze";
  }
  if (rank == 2){
    var rankWord = "Silver";
  }
  if (rank == 3){
    var rankWord = "Gold";
  }
  if (rank == 4){
    var rankWord = "Crystal";
  }
  if (rank == 5){
    var rankWord = "Rainbow";
  }

  var ruowner = "❌ " + "You DO NOT own PixaWitch" + name + " ❌";

  for (let i = 0; i < thebalance; i++) {
    var theowner = await window.contract.methods.tokenOfOwnerByIndex(walletAddress,i).call();

      if (theowner == name) {
        ruowner = "💚 " + "You own PixaWitch" + name + " 💚";
      }
    
  }

  return {tokenURI: result, displayRank: rankWord, ownershipWitch: ruowner}
}





export const bronzeRank = async(num,walletAddress) => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
 window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract2.methods.allowance(walletAddress, witchescontractAddress).call();
  
  if (wazaaa < 5001) {

  const transactionParameters1 = {
        to: contractAddresspixa, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.approve(witchescontractAddress,1000000).encodeABI()//make call to NFT smart contract 
        };

  const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters1],
        });
  } 
  else {

  }


 const transactionParameters = {
        to: witchescontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.upgradeWitch(num,1).encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}



export const silverRank = async(num,walletAddress) => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
 window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract2.methods.allowance(walletAddress, witchescontractAddress).call();
  
  if (wazaaa < 5001) {

  const transactionParameters1 = {
        to: contractAddresspixa, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.approve(witchescontractAddress,1000000).encodeABI()//make call to NFT smart contract 
        };

  const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters1],
        });
  } 
  else {

  }


 const transactionParameters = {
        to: witchescontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.upgradeWitch(num,2).encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}



export const goldRank = async(num,walletAddress) => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
 window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract2.methods.allowance(walletAddress, witchescontractAddress).call();
  
  if (wazaaa < 5001) {

  const transactionParameters1 = {
        to: contractAddresspixa, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.approve(witchescontractAddress,1000000).encodeABI()//make call to NFT smart contract 
        };

  const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters1],
        });
  } 
  else {

  }


 const transactionParameters = {
        to: witchescontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.upgradeWitch(num,3).encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}



export const crystalRank = async(num,walletAddress) => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
 window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract2.methods.allowance(walletAddress, witchescontractAddress).call();
  
  if (wazaaa < 5001) {

  const transactionParameters1 = {
        to: contractAddresspixa, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.approve(witchescontractAddress,1000000).encodeABI()//make call to NFT smart contract 
        };

  const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters1],
        });
  } 
  else {

  }


 const transactionParameters = {
        to: witchescontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.upgradeWitch(num,4).encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}




export const rainbowRank = async(num,walletAddress) => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
 window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract2.methods.allowance(walletAddress, witchescontractAddress).call();
  
  if (wazaaa < 5001) {

  const transactionParameters1 = {
        to: contractAddresspixa, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.approve(witchescontractAddress,1000000).encodeABI()//make call to NFT smart contract 
        };

  const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters1],
        });
  } 
  else {

  }


 const transactionParameters = {
        to: witchescontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.upgradeWitch(num,5).encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }
}


export const checkPixaStaked = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
  var wazaaa = await window.contract.methods.totalStaked().call();

  return {currenttotalstaked: wazaaa}
}


export const checkTotalPixa = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract.methods.totalSupply().call();

  return {currenttotalsupply: wazaaa}
}

export const checkPixaInRewards = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract.methods.balanceOf(rewardscontractAddress).call();

  return {currentpixainrewards: wazaaa}
}


export const checkPixaInWallet = async(walletAddress) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract.methods.balanceOf(walletAddress).call();

  return {currentpixainwallet: wazaaa}
}


export const checkDaoInWallet = async(walletAddress) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.balanceOf(walletAddress).call();

  return {currentdaoinwallet: wazaaa}
}

export const checkPixaInDAO = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
  var wazaaa = await window.contract.methods.balanceOf(contractAddress).call();

  return {currentpixaindao: wazaaa}
}

export const checkUserStakeLevel = async(walletAddress) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
  var wazaaa = await window.contract.methods.currentStaking(walletAddress).call();

  return {currentusersstakelevel: wazaaa}
}

export const mintOneNFT = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);

 const transactionParameters = {
        to: witchescontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: parseInt(web3.utils.toWei("0.03","ether")).toString(16),
        'data': window.contract.methods.mintWitchEth().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}



export const unstakePixa1 = async () => {

    window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
    const transactionParameters = {
          to: rewardscontractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.unstakePixa().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }


};




export const claimNFTRewards1 = async () => {

    window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
    const transactionParameters = {
          to: rewardscontractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.claimRewards().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }


};




export const stakePixa1 = async (stakeLevel,walletAddress) => {

    window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
    window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);

  var wazaaa = await window.contract2.methods.allowance(walletAddress, rewardscontractAddress).call();
  
  if (wazaaa < 50000) {

  const transactionParameters1 = {
        to: contractAddresspixa, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.approve(rewardscontractAddress,100000).encodeABI()//make call to NFT smart contract 
        };

  const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters1],
        });
  } 
  else {
  }
    const transactionParameters = {
          to: rewardscontractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.stakePixa(stakeLevel).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }


};




export const calculateCurrentRewards1= async(walletAddress) => {
//var addressowner = rewardsaddress; //"0x7cA6B4645B71f874A35Bec18F0e997E51D8d815C"
var addressowner = walletAddress
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
  window.contract2 = await new web3.eth.Contract(wizardcontractABI, wizardcontractAddress);
  window.contract3 = await new web3.eth.Contract(wyverncontractABI, wyverncontractAddress);
  window.contract4 = await new web3.eth.Contract(pixalecontractABI, pixalecontractAddress);
  window.contract5 = await new web3.eth.Contract(relicscontractABI, relicscontractAddress);
  window.contract6 = await new web3.eth.Contract(wizardaocontractABI, wizardaocontractAddress);
  window.contract7 = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);

  var result1 = await window.contract2.methods.balanceOf(addressowner).call();
  var result2 = await window.contract3.methods.balanceOf(addressowner).call();
  var result3 = await window.contract4.methods.balanceOf(addressowner).call();
  var result4 = await window.contract5.methods.balanceOf(addressowner).call();
  var result5 = await window.contract6.methods.balanceOf(addressowner).call();
  var result6 = await window.contract7.methods.balanceOf(addressowner).call();
  var timeremaining = await window.contract.methods.claimerTime(addressowner).call();
  var stakelevel = await window.contract.methods.currentStakingLevel(addressowner).call();
  var stkamt = await window.contract.methods.currentStaking(addressowner).call();

  var total = result1*100 + result2*70 + result3*35 + result4*5 + result5*100 + result6*50;
  var bvmult = 1;
  var overflowAmount = 0;
  var multAmount = 0;

  if (total >= 100) {
    bvmult = 2;
    overflowAmount = 0;
    multAmount = total;
  }
  if (total >= 300) {
    bvmult = 3;
    overflowAmount = 0;
    multAmount = total;
  }
  if (total > 1000) {
    bvmult = 4;
    overflowAmount = total - 1000;
    multAmount = 1000;
  }
  if (total > 3000) {
    bvmult = 5;
    overflowAmount = total - 1000;
    multAmount = 1000;
  }
  var stakingmult = parseInt(stakelevel)+ 1

  var finalTotal = (parseInt(stakelevel)+ 1)*bvmult*multAmount + overflowAmount*bvmult;


  //make call to NFT smart contract 
  var date = new Date(timeremaining * 1000);
// Hours part from the timestamp
  var hours = date.getHours();
// Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
// Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

// Will display time in 10:30:23 format
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  return {totalrewardsavailable: finalTotal, bvamount: total,  timeleft: date.toLocaleDateString(), amountstaked:stkamt, HM: bvmult, SM: stakingmult}
}





export const mintOneRelic = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(relicscontractABI, relicscontractAddress);

 const transactionParameters = {
        to: relicscontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.mintRelic().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}



export const mintThreeNFT = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);

 const transactionParameters = {
        to: witchescontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: parseInt(web3.utils.toWei("0.06","ether")).toString(16),
        'data': window.contract.methods.mintThreeWitchEth().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}


export const mintPixaNFT = async(walletAddress) => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);
 window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);

  var wazaaa = await window.contract2.methods.allowance(walletAddress, witchescontractAddress).call();
  
  if (wazaaa < 1500) {

  const transactionParameters1 = {
        to: contractAddresspixa, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.approve(witchescontractAddress,1000000).encodeABI()//make call to NFT smart contract 
        };

  const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters1],
        });
  } 
  else {

  }

 const transactionParameters = {
        to: witchescontractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.mintWitchPixa().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}




export const mintNFTDAO = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.claimToken().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}


export const mintMultipleDAO = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.claimTokenLoop().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }


}



    



